import PageTitle from '@components/PageTitle'
import WithPortalAuthorization from '@hocs/WithPortalAuthorization'
import Dashboard from '@layouts/Dashboard/Layout'
import React from 'react'

const Home: React.FC = () => (
  <>
    <PageTitle title={'Student Dashboard'} />
    <Dashboard />
  </>
)

export default WithPortalAuthorization(Home, {
  id: 'page::dashboard::student',
  queryParams: ['ie', 'sid', 'path'],
})
